import React, { useState, useEffect } from 'react';
import "./Header.css";
import HeaderLogo from "../../svg/HeaderLogoSvg";
import HeaderMenu from "../../svg/HeaderMenuSvg";
import CloseSvg from "../../svg/CloseSvg";

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(prev => !prev);
    }

    useEffect(() => {
        const smoothScroll = (event) => {
            event.preventDefault();
            const targetId = event.target.getAttribute('href').slice(1);
            const targetElement = document.getElementById(targetId);

            const headerHeight = 0;

            // Calculate the adjusted scroll position
            const scrollPosition = targetElement.getBoundingClientRect().top + window.scrollY - headerHeight;

            window.scrollTo({
                top: scrollPosition,
                behavior: 'smooth',
            });
            setIsMenuOpen(false);
        };

        const anchorLinks = document.getElementsByClassName('link');
        Array.from(anchorLinks).forEach((link) => {
            link.addEventListener('click', smoothScroll);
        });

        return () => {
            Array.from(anchorLinks).forEach((link) => {
                link.removeEventListener('click', smoothScroll);
            });
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = 700; // Adjust this value as needed
            const scrollY = window.scrollY;
            const header = document.getElementById("header-inner");

            if (scrollY > scrollThreshold) {
                header.classList.add("show"); // Apply a CSS class to show the header
            } else {
                header.classList.remove("show"); // Remove the CSS class to hide the header
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <header id='header'>
            <div className='inner' id='header-inner'>
                <HeaderLogo className="logo" />
                <HeaderMenu onClick={toggleMenu} className="menu" />
            </div>
            {isMenuOpen &&
                <div className="overlay" />
            }
            <aside className={`side-menu ${isMenuOpen ? 'open' : ''}`}>
                <CloseSvg onClick={toggleMenu} className="close" />
                <HeaderLogo className="menu-logo" />
                <nav>
                    <a href="#trim" className="link">Чистовая отделка</a>
                    <a href="#video" className="link">О проекте</a>
                    <a href="#adv" className="link">Преимущества</a>
                    <a href="#map" className="link">Расположение</a>
                    <a href="#all-in-one" className="link">All in one</a>
                    <a href="#gallery" className="link">Галерея</a>
                </nav>
            </aside>
        </header>
    )
}

export default Header;