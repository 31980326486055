import React, { useRef, useState } from 'react';
import "./ModalFrom.css";
import Modal from '../modal/Modal';
import Request from '../request/Request';
import FormImg from "../../images/form.jpg";
import { motion, AnimatePresence } from "framer-motion";

const ModalForm = ({ setIsOpen, isOpen, formId }) => {
  const [show, setShow] = useState(true);
  const setIsModalWithDelay = (bool) => {
    setShow(bool);
    setTimeout(() => {
      setIsOpen(bool);
    }, 300);
  };
  return (
    <Modal setIsOpen={setIsModalWithDelay} isOpen={isOpen} setShow={setShow}>
      <AnimatePresence>
        {
          show &&
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.3 }}
            className='form-con'
          >
            <img src={FormImg} alt="" />
            <div className="inner">
              <h2>оставьте заявку</h2>
              <p>чтобы получить индивидуальную консультацию!</p>
              <Request formId={formId} />
            </div>
          </motion.div>
        }
      </AnimatePresence>

    </Modal>
  )
}

export default ModalForm;