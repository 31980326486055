import { AnimationOnScroll } from "react-animation-on-scroll";

const Animation = ({ children, ...props }) => {
    if (props.type === "left") {
        return (
            <AnimationOnScroll
                animateIn='fade-in-right'
                {...props}>
                {children}
            </AnimationOnScroll>
        )
    }

    else if (props.type === "right") {
        return (
            <AnimationOnScroll
                animateIn='fade-in-left'
                {...props}>
                {children}
            </AnimationOnScroll>
        )
    }

}

export default Animation;