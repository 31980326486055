import "./Gallery.css";
import React, { useState, useRef } from "react";
import { Carousel } from "antd";
import Gallery1Img from "../../images/gallery1.jpg";
import Gallery2Img from "../../images/gallery2.jpg";
import Gallery3Img from "../../images/gallery3.jpg";
import Gallery4Img from "../../images/gallery4.jpg";
import Gallery5Img from "../../images/gallery5.jpg";
import Gallery6Img from "../../images/gallery6.jpg";
import Gallery7Img from "../../images/gallery7.jpg";
import Gallery8Img from "../../images/gallery8.jpg";
import Gallery9Img from "../../images/gallery9.jpg";
import Gallery10Img from "../../images/gallery10.jpg";
import Gallery11Img from "../../images/gallery11.jpg";
import Gallery12Img from "../../images/gallery12.jpg";
import Gallery13Img from "../../images/gallery13.jpg";
import Gallery14Img from "../../images/gallery14.jpg";
import Modal from "../modal/Modal";

const Gallery = () => {
  const [type, setType] = useState("Экстерьер");
  const [isOpen, setIsOpen] = useState(false);
  const carouselRef = useRef(null);
  const ext = [
    Gallery1Img,
    Gallery2Img,
    Gallery3Img,
    Gallery4Img,
    Gallery5Img,
    Gallery6Img,
    Gallery7Img,
    Gallery8Img,
    Gallery9Img,
  ];
  const holl = [
    Gallery10Img,
    Gallery11Img,
    Gallery12Img,
    Gallery13Img,
    Gallery14Img,
  ];
  const handleNext = () => {
    carouselRef.current.next();
  };

  const handlePrev = () => {
    carouselRef.current.prev();
  };
  return (
    <section id="gallery">
      <div className="inner">
        <h2>{type}</h2>
        <div onClick={() => setIsOpen(true)}>
          <Carousel autoplay={true} dots={false} className="carousel">
            {type === "Экстерьер" &&
              ext.map((el, i) => (
                <div className="car-item" key={i}>
                  <img src={el} alt="" />
                </div>
              ))}
            {type === "Подъезд" &&
              holl.map((el, i) => (
                <div className="car-item" key={i}>
                  <img src={el} alt="" />
                </div>
              ))}
          </Carousel>
        </div>

        <div className="buttons">
          <button
            className={type === "Экстерьер" ? "active" : ""}
            onClick={() => setType("Экстерьер")}
          >
            Экстерьер
          </button>
          <button
            className={type === "Подъезд" ? "active" : ""}
            onClick={() => setType("Подъезд")}
          >
            Подъезд
          </button>
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <svg
        onClick={handlePrev}
          style={{ transform: "rotate(180deg)", margin: 10, cursor: "pointer" }}
          width={26}
          height={54}
          viewBox="0 0 26 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 51L21.3727 31.2425C23.5424 28.9091 23.5424 25.0909 21.3727 22.7576L3 3"
            stroke="white"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <Carousel dots={false} className="modal-carousel" ref={carouselRef}>
          {type === "Экстерьер" &&
            ext.map((el, i) => (
              <div className="car-item" key={i}>
                <img src={el} alt="" />
              </div>
            ))}
          {type === "Подъезд" &&
            holl.map((el, i) => (
              <div className="car-item" key={i}>
                <img src={el} alt="" />
              </div>
            ))}
        </Carousel>
        <svg
          onClick={handleNext}
          style={{ margin: 10, cursor: "pointer" }}
          width={26}
          height={54}
          viewBox="0 0 26 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 51L21.3727 31.2425C23.5424 28.9091 23.5424 25.0909 21.3727 22.7576L3 3"
            stroke="white"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Modal>
    </section>
  );
};

export default Gallery;
