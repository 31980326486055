import React, { useRef, useState } from 'react';
import './AllInOne.css';
import LeftArrowSvg from '../../svg/LeftArrowSvg';
import RightArrowSvg from '../../svg/RightArrowSvg';
import Carousel1Img from "../../images/carousel1.jpg";
import Carousel2Img from "../../images/carousel2.jpg";
import Carousel3Img from "../../images/carousel3.jpg";
import Carousel4Img from "../../images/carousel4.jpg";
import Carousel5Img from "../../images/carousel5.jpg";
import Carousel6Img from "../../images/carousel6.jpg";
import Carousel7Img from "../../images/carousel7.jpg";
import Carousel8Img from "../../images/carousel8.jpg";
import BgSvg from "../../svg/VideoBgSvg";

const AllInOne = () => {
    const sliderArray = [
        {
            image: Carousel1Img,
            title: "Караоке",
            text: "Проснувшись с утра вам потребуется сделать всего пару шагов до лифта, спуститься на несколько этажей вниз, чтобы оказаться в оборудованном фитнес-зале. Занимайтесь в комфортном пространстве без посторонних людей, это отличная возможность начать день с тренировки, экономя важные минуты на дорогу. Специально для резидентов RAMS Signature он доступен круглосуточно."
        },
        {
            image: Carousel2Img,
            title: "Гостевой номер",
            text: "Собирать родственников на семейные мероприятия или встречать долгожданных друзей становится легче. Неудобства связанные с их размещением не возникнут: в системе All-In-One предусмотрены комнаты для гостей. Комфортабельный номер в жилом комплексе – это дополнительное пространство, которое могут использовать резиденты RAMS Signature."
        },
        {
            image: Carousel3Img,
            title: "Music Room",
            text: "Позвольте себе раствориться в музыке, подпевать в унисон аккордам и играть на инструментах в собственном Music Room в вашем доме. Оборудованное пространство оснащено всем необходимым для раскрытия вашего творческого потенциала. Здесь вы предоставлены только себе и своему творчеству."
        },
        {
            image: Carousel4Img,
            title: "SPA Center",
            text: "После продуктивного рабочего дня хочется полноценного отдыха, чтобы восстановить силы для новых достижений. Посвятите время себе в атмосферном SPA в вашем ЖК, это возможность отправиться на “солнечные берега” не выходя из дома. Вам не потребуется выезжать в поисках салона, забронируйте время и посетите любую процедуру, спустившись на первый этаж."
        },
        {
            image: Carousel5Img,
            title: "Cinema Room+PS Room",
            text: "Только представьте, если развлечения были бы доступны в вашем доме, без нужды ездить в город. В RAMS Signature жители пользуются этим преимуществом, организовывая вечерние просмотры любимых фильмов в кругу своих людей, устраивая турниры на игровой приставке с близкими друзьями или выбирая лучшего певца в караоке."
        },
        {
            image: Carousel6Img,
            title: "Kids Room",
            text: "Отправляясь на важное мероприятие, большой шопинг или по другим делам, бывает, что не с кем оставить детей. Игровая комната на первом этаже предоставляет удобство и экономит время, где ребенок находится под чутким присмотром воспитателей. Маленькие жители ЖК проведут веселое время за играми в яркой детской, пока вы можете посвятить время для себя и своих занятий."
        },
        {
            image: Carousel7Img,
            title: "Coworking",
            text: "Деловой мегаполис находится в беспрерывном потоке движения, поэтому часпик в городе – это обычное дело. А что делать, если нужно срочно заключить важный контракт или подъехать на конференцию? Чтобы успеть везде, необязательно выходить из своего дома, жилой комплекс предоставляет коворкинг-кабинеты, в которых удобно устраивать встречи, работать в тишине и спокойствии."
        },
        {
            image: Carousel8Img,
            title: "Fitness Room",
            text: "Проснувшись с утра вам потребуется сделать всего пару шагов до лифта, спуститься на несколько этажей вниз, чтобы оказаться в оборудованном фитнес-зале. Занимайтесь в комфортном пространстве без посторонних людей, это отличная возможность начать день с тренировки, экономя важные минуты на дорогу. Специально для резидентов RAMS Signature он доступен круглосуточно."
        },
    ];

    const sliderRef = useRef(null);
    const [isDown, setIsDown] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [leftHovered, setLeftHovered] = useState(false);
    const [rightHovered, setRightHovered] = useState(false);


    const handleMouseDown = (e) => {
        setIsDown(true);
        setStartX(e.pageX - sliderRef.current.offsetLeft);
        setScrollLeft(sliderRef.current.scrollLeft);
    };

    const handleMouseLeave = () => {
        setIsDown(false);
    };

    const handleMouseUp = () => {
        setIsDown(false);
    };

    const handleMouseMove = (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - sliderRef.current.offsetLeft;
        const walk = (x - startX) * 3; // scroll-fast
        sliderRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleScroll = (direction) => {
        const { current } = sliderRef;
        const containerWidth = current.offsetWidth;
        const scrollAmount = containerWidth / 2;
        const maxScrollLeft = current.scrollWidth - containerWidth;

        let targetScrollLeft;
        if (direction === 'left') {
            targetScrollLeft = current.scrollLeft - scrollAmount;
        } else {
            targetScrollLeft = current.scrollLeft + scrollAmount;
        }

        const clampedScrollLeft = Math.max(0, Math.min(targetScrollLeft, maxScrollLeft));
        current.scrollTo({
            left: clampedScrollLeft,
            behavior: 'smooth',
        });
    };


    return (
        <section id="all-in-one">
            <BgSvg className="bg" />
            <div className="header">
                <div>
                    <h2>All-in-one</h2>
                    <p>Ваш незабываемый комфорт</p>
                </div>
                <div className="buttons">
                    <button onClick={() => handleScroll("left")}
                        onMouseEnter={() => setLeftHovered(true)}
                        onMouseLeave={() => setLeftHovered(false)}
                    >
                        <LeftArrowSvg color={leftHovered ? '#38877B' : '#09183B'} />
                    </button>
                    <button onClick={() => handleScroll("right")}
                        onMouseEnter={() => setRightHovered(true)}
                        onMouseLeave={() => setRightHovered(false)}
                    >
                        <RightArrowSvg color={rightHovered ? '#38877B' : '#09183B'} />
                    </button>
                </div>
            </div>
            <div className='slider' ref={sliderRef}
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseLeave}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                style={isDown ? {cursor: "grabbing"} : {}}
            >
                {sliderArray.map((el, i) => (
                    <div key={i} className="card">
                        <div className='card-bg' style={{ backgroundImage: `url(${el.image})` }} />
                        <h3>
                            {el.title}
                        </h3>
                        <p>
                            {el.text}
                        </p>
                    </div>
                ))}

            </div>
        </section >
    );
};

export default AllInOne;
