const SVGComponent = (props) => (
    <svg
      viewBox="0 0 772 1149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M-639.382 1112.5C-308.12 1220.65 320.497 1061.29 351.934 865.612C383.371 669.938 -228.396 664.429 -252.819 376.755C-277.242 89.0822 683.791 -133.847 766.076 97.5713C861.85 366.928 -546.351 766.51 -1515.5 744.174"
        stroke="url(#paint0_radial_273_664)"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <radialGradient
          id="paint0_radial_273_664"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(334.853 857.347) rotate(-111.766) scale(1209.52 1591.39)"
        >
          <stop stopColor="#38877B" stopOpacity={0} />
          <stop offset={1} stopColor="#38877B" />
        </radialGradient>
      </defs>
    </svg>
  );
  export default SVGComponent;