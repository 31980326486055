const SVGComponent = (props) => (
    <svg
      viewBox="0 0 711 1177"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M789.839 1C512.442 10.6819 85.746 301.811 115.738 456.795C145.73 611.778 604.908 448.602 701.848 657.136C798.789 865.669 140.79 1295.26 15.9463 1144.63C-129.364 969.313 814.859 285.283 1546 36.9635"
        stroke="url(#paint0_radial_273_654)"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <radialGradient
          id="paint0_radial_273_654"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(130.777 458.307) rotate(48.1543) scale(963.419 1267.59)"
        >
          <stop stopColor="#38877B" stopOpacity={0} />
          <stop offset={1} stopColor="#38877B" />
        </radialGradient>
      </defs>
    </svg>
  );
  export default SVGComponent;