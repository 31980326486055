import React, { useState } from "react";
import "./QA.css";
import { Collapse, Divider } from "antd";
import CollapseSvg from "../../svg/CollapseSvg";

const QA = () => {
    const { Panel } = Collapse;
    const [rotatedPanel, setRotatedPanel] = useState(null);
    const handleExpandIconClick = (panelkey) => {
        setRotatedPanel(panelkey === rotatedPanel ? null : panelkey);
    };

    const customPanelHeader = (label) => {
        return (
            <div className="label">
                <div>
                    <span style={{ marginRight: 8 }}>{label}</span>
                </div>
            </div>
        );
    };

    return (
        <section id="qa">
            <Collapse
                accordion="true"
                expandIconPosition="end"
                collapsible="icon"
                ghost
                expandIcon={({ isActive }) =>
                    isActive ? (
                        <CollapseSvg className="icon rotate" />
                    ) : (
                        <CollapseSvg className="icon" />
                    )
                }
                onChange={(key) => handleExpandIconClick(key)}
            >
                <Panel
                    header={customPanelHeader("Где расположен жилой комплекс?")}
                    key="1"
                >
                    <div className="content">
                        <div className="text">
                            <p>
                                RAMS Signature расположен в деловом центре
                                Алматы на Тимирязева-Байтурсынова, рядом с ТРЦ
                                Forum.
                            </p>
                        </div>
                    </div>
                </Panel>
                <Divider style={{ margin: "0" }} />
                <Panel
                    header={customPanelHeader(
                        "Есть ли свободные квартиры в продаже?"
                    )}
                    key="2"
                >
                    <div className="content">
                        <div className="text">
                            <p>
                                Да, есть. Оставьте заявку и мы подберем
                                планировку для вас.
                            </p>
                        </div>
                    </div>
                </Panel>
                <Divider style={{ margin: "0" }} />
                <Panel
                    header={customPanelHeader(
                        "Сколько комнат в квартирах RAMS Signature?"
                    )}
                    key="3"
                >
                    <div className="content">
                        <div className="text">
                            <p>
                                Квартиры RAMS Signature разделены на одну, две,
                                три и четыре комнаты.
                            </p>
                        </div>
                    </div>
                </Panel>
                <Divider style={{ margin: "0" }} />
                <Panel
                    header={customPanelHeader("Какая отделка в квартирах?")}
                    key="4"
                >
                    <div className="content">
                        <div className="text">
                            <p>
                                Квартиры RAMS Signature выполнены в чистовой
                                отделке нового формата. Жители получат полностью
                                обустроенное жилье с комплектом сантехники и
                                кухонным гарнитуром.
                            </p>
                            <p>
                                В чистовую отделку также входят: штукатурка,
                                шпатлевка, левкас и покраска стен. Стяжка полов,
                                установка плинтуса, укладка ламината. Монтаж
                                освещения и электропроводки. Покрытие мокрых зон
                                квартиры керамогранитом.
                            </p>
                        </div>
                    </div>
                </Panel>
                <Divider style={{ margin: "0" }} />
                <Panel
                    header={customPanelHeader("Есть ли закрытый двор?")}
                    key="5"
                >
                    <div className="content">
                        <div className="text">
                            <p>Да.</p>
                        </div>
                    </div>
                </Panel>
                <Divider style={{ margin: "0" }} />
                <Panel
                    header={customPanelHeader("Когда сдача в эксплуатацию?")}
                    key="6"
                >
                    <div className="content">
                        <div className="text">
                            <p>1 квартал 2025 года</p>
                        </div>
                    </div>
                </Panel>
                <Divider style={{ margin: "0" }} />
            </Collapse>
        </section>
    );
};

export default QA;
