const SVGComponent = (props) => (
    <svg
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.1826 9.71727H0V7.28268H16.1825L10.4338 1.72151L12.2134 0L21 8.5L12.2134 17L10.4338 15.2785L16.1826 9.71727Z"
        fill={props.color}
      />
    </svg>
  );
  export default SVGComponent;