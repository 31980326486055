const SVGComponent = (props) => (
    <svg
      viewBox="0 0 371 371"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M204.56 184.666L176.44 167.481C176.203 167.336 175.932 167.257 175.654 167.252C175.377 167.247 175.103 167.316 174.861 167.452C174.619 167.587 174.417 167.785 174.277 168.025C174.136 168.264 174.063 168.537 174.062 168.814V203.185C174.063 203.463 174.136 203.735 174.277 203.975C174.417 204.214 174.619 204.412 174.861 204.548C175.103 204.684 175.377 204.752 175.654 204.747C175.932 204.742 176.203 204.663 176.44 204.518L204.56 187.333C204.789 187.193 204.977 186.997 205.108 186.764C205.239 186.531 205.308 186.267 205.308 186C205.308 185.732 205.239 185.469 205.108 185.235C204.977 185.002 204.789 184.806 204.56 184.666Z"
        fill="white"
      />
      <circle cx={185.5} cy={185.5} r={185} stroke="white" />
    </svg>
  );
  export default SVGComponent;
  