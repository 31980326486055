const SVGComponent = (props) => (
    <svg
      width={14}
      height={10}
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.770508 5L12.7705 5M12.7705 5L8.27051 9.5M12.7705 5L8.27051 0.5"
        stroke="white"
      />
    </svg>
  );
  export default SVGComponent;