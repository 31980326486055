import React, {useContext} from "react";
import BgSvg from "../../svg/CircleBgSvg";
import GoMainSvg from "../../svg/GoMainSvg";
import "./Submited.css";
import { AppContext } from "../context/context";

const Submited = () => {
  const { setIsSubmitted } = useContext(AppContext);
  return (
    <section id="sub">
      <div className="inner">
        <BgSvg className="bg" />
        <BgSvg className="bg" />
        <BgSvg className="bg" />
        <BgSvg className="bg" />
        <div className="text">
          <h1>СПАСИБО!</h1>
          <h2>ЗАЯВКА ОТПРАВЛЕНА</h2>
          <p>
            Спасибо за проявленный интерес. Наши менеджеры свяжутся с Вами в
            ближайшее время.
          </p>
          <GoMainSvg onClick={() => setIsSubmitted(false)} className="gomain" />
        </div>
      </div>
    </section>
  );
};

export default Submited;
