import React, { useState } from "react";
import Tabs from "../tabs/Tabs";
import TrimSvg from "../../svg/TrimSvg";
import "./Trim.css";
import { Carousel } from "antd";
import Trim1Img from "../../images/trim1.jpg";
import Trim2Img from "../../images/trim2.jpg";
import Trim3Img from "../../images/trim3.jpg";
import Trim4Img from "../../images/trim4.jpg";
import Trim5Img from "../../images/trim5.jpg";
import Levit1Img from "../../images/levit1.jpg";
import Levit2Img from "../../images/levit2.jpg";
import Levit3Img from "../../images/levit3.jpg";
import { Parallax } from "react-scroll-parallax";
import Trim2Svg from "../../svg/Trim2Svg";
import AnimationText from "../animation/AnimationText";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Trim = () => {
    const [isLoading, setLoading] = useState(true);
    const handleIframeLoad = () => {
        setLoading(false);
    };
    const carouselItems = [
        {
            image: Trim1Img,
            key: 1,
        },
        {
            image: Trim2Img,
            key: 2,
        },
        {
            image: Trim3Img,
            key: 3,
        },
        {
            image: Trim4Img,
            key: 4,
        },
        {
            image: Trim5Img,
            key: 5,
        },
    ];
    return (
        <section id="trim">
            <Parallax translateX={[0, -10]}>
                <TrimSvg className="bg" />
            </Parallax>
            <div className="gallery-inner">
                <AnimationText id="a">
                    <h1>Чистовая отделка</h1>
                </AnimationText>
                <AnimationText id="b">
                    <p>
                        Современный житель мегаполиса ценит главный ресурс –
                        время.
                        <br />
                        Все квартиры в RAMS Signature сдаются в чистовой
                        отделке, а это значит резиденты получают обустроенное
                        жилье с комплектом сантехники и кухонным гарнитуром.
                        Остается лишь расставить мебель и начинать новую главу
                        жизни.{" "}
                    </p>
                </AnimationText>
                <div className="gallery">
                    <div className="cards">
                        <div className="card">
                            <h3>01</h3>
                            <div>
                                <h5>Выгода</h5>
                                <p>
                                    Новый формат идеально подходит для тех, кто
                                    не хочет тратить средства, силы и время на
                                    ремонт. Квартиры в RAMS Signature передаются
                                    владельцу с чистовым ремонтом, комплектом
                                    сантехники и встроенной кухней. Заезжайте в
                                    готовую собственную квартиру и наслаждайтесь
                                    жизнью в комфорте с первых дней.
                                </p>
                            </div>
                        </div>
                        <div className="card">
                            <h3>02</h3>
                            <div>
                                <h5>Чистота и тишина</h5>
                                <p>
                                    С первого дня ввода в эксплуатацию дом будет
                                    комфортным и дружелюбным пространством без
                                    прежнего шума и «следов» ремонтных бригад.
                                </p>
                            </div>
                        </div>
                        <div className="card">
                            <h3>03</h3>
                            <div>
                                <h5>Дизайн</h5>
                                <p>
                                    Отделка квартир предлагается в современном
                                    лаконичном стиле. На базе дизайна,
                                    представленного в данном формате, легко
                                    создать свой индивидуальный интерьер.
                                </p>
                            </div>
                        </div>
                    </div>
                    <Carousel fade className="carousel" autoplay>
                        {carouselItems.map((item) => (
                            <div className="car-item" key={item.key}>
                                <img
                                    src={item.image}
                                    alt={`Image ${item.key}`}
                                />
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
            <div className="inner">
                <div className="tour">
                    <AnimationText id="c">
                        <h1>3D Tour</h1>
                    </AnimationText>
                    <Tabs />
                </div>
                <div className="levitate">
                    <Parallax className="bg2" translateX={[0, 20]}>
                        <Trim2Svg />
                    </Parallax>
                    <div className="left">
                        <Parallax speed={6}>
                            <img src={Levit1Img} alt="" />
                        </Parallax>
                        <Parallax speed={20}>
                            <img src={Levit2Img} alt="" />
                        </Parallax>
                    </div>
                    <div className="right">
                        <h2>
                            <AnimationText id="d1">
                                <span>Мировое</span>
                            </AnimationText>
                            <AnimationText id="d2">
                                <span>восприятие</span>
                            </AnimationText>
                            <AnimationText id="d3">
                                <span>качества</span>
                            </AnimationText>
                        </h2>
                        <Parallax speed={12}>
                            <img src={Levit3Img} alt="" />
                        </Parallax>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Trim;
