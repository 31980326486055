import "./Advantages.css";
import React, { useState } from "react";
import { Collapse, Divider } from "antd";
import CollapseSvg from "../../svg/CollapseSvg";
import Adv1Img from "../../images/adv1.jpg";
import Adv2Img from "../../images/adv2.jpg";
import Adv3Img from "../../images/adv3.jpg";
import Adv4Img from "../../images/adv4.jpg";
import Adv5Img from "../../images/adv5.jpg";
import Adv6Img from "../../images/adv6.jpg";
import Adv7Img from "../../images/adv7.jpg";
import Adv8Img from "../../images/adv8.jpg";

const Advantages = () => {
    const { Panel } = Collapse;

    const customPanelHeader = (label, index) => {
        return (
            <div className="label">
                <div>
                    <span className="num">{index}</span>
                    <span style={{ marginRight: 8 }}>{label}</span>
                </div>
            </div>
        );
    };

    const [rotatedPanel, setRotatedPanel] = useState(null);

    const handleExpandIconClick = (panelkey) => {
        setRotatedPanel(panelkey === rotatedPanel ? null : panelkey);
    };

    return (
        <section id="adv">
            <div className="inner">
                <h2>Преимущества</h2>
                <div>
                    <Collapse
                        accordion="true"
                        expandIconPosition="end"
                        collapsible="icon"
                        ghost
                        expandIcon={({ isActive }) => (isActive ? <CollapseSvg className="icon rotate" /> : <CollapseSvg className="icon" />)}
                        onChange={(key) => handleExpandIconClick(key)}
                    >
                        <Panel header={customPanelHeader("Чистовая отделка", "01")} key="1">
                            <div className="content">
                                <div className="img" style={{ backgroundImage: `url(${Adv1Img})` }} alt="" />
                                <div className="text">
                                    <p>Современный житель мегаполиса ценит главный ресурс – время.</p>
                                    <p>
                                        Все квартиры в Rams Signature сдаются в чистовой отделке, а это значит резиденты получают обустроенное жилье с комплектом сантехники и
                                        кухонным гарнитуром. Остается лишь расставить мебель и начинать новую главу жизни.
                                    </p>
                                </div>
                            </div>
                        </Panel>
                        <Divider style={{ margin: "0" }} />
                        <Panel header={customPanelHeader("Видеонаблюдение", "02")} key="2">
                            <div className="content">
                                <div className="img" style={{ backgroundImage: `url(${Adv2Img})` }} alt="" />
                                <div className="text">
                                    <p>
                                        В RAMS Signature вы можете контролировать перемещение людей по территории вашего дома. Это возможно благодаря системе контроля и управления
                                        доступом, которая позволяет собственникам контролировать пропуск посетителей не только с помощью общения, но и наблюдая за пришедшим
                                        человеком по видео. К тому же фиксируется время каждого прибывшего человека на территорию ЖК. Таким образом, ваша частная и семейная жизнь
                                        находится под круглосуточной защитой. Дети всегда находятся под наблюдением охраны, а также в поле зрения своих родителей.
                                    </p>
                                </div>
                            </div>
                        </Panel>
                        <Divider style={{ margin: "0" }} />
                        <Panel header={customPanelHeader("Work-out зоны", "03")} key="3">
                            <div className="content">
                                <div className="img" style={{ backgroundImage: `url(${Adv3Img})` }} alt="" />
                                <div className="text">
                                    <p>
                                        Поддерживать форму круглый год легко на work-out зонах на территории RAMS Signature. Благодаря специально оборудованному пространству для
                                        спорта тренироваться и заряжаться бодростью можно в любое время. Занятия на свежем отдыхе поднимает настроение и дарит энергию на весь день.
                                    </p>
                                </div>
                            </div>
                        </Panel>
                        <Divider style={{ margin: "0" }} />
                        <Panel header={customPanelHeader("Детские площадки", "04")} key="4">
                            <div className="content">
                                <div className="img" style={{ backgroundImage: `url(${Adv4Img})` }} alt="" />
                                <div className="text">
                                    <p>
                                        Детские площадки имитируют натуральный природный ландшафт с помощью техники — геопластика, которая гарантирует безопасность и увлекательную
                                        игру. Юные умы погружаются в мир фантазии, так детство городских детей наполняется яркими воспоминаниями.
                                    </p>
                                </div>
                            </div>
                        </Panel>
                        <Divider style={{ margin: "0" }} />
                        <Panel header={customPanelHeader("Личные террасы", "05")} key="5">
                            <div className="content">
                                <div className="img" style={{ backgroundImage: `url(${Adv5Img})` }} alt="" />
                                <div className="text">
                                    <p>
                                        С личных террас на верхних этажах RAMS Signature открывается живописная панорама на заснеженные пики Заилийского Алатау и стеклянные
                                        верхушки алматинских высоток. Здесь вы будете отдыхать в кругу близких людей, наслаждаясь свежим воздухом и видом на главную алматинскую
                                        достопримечательность – горы
                                    </p>
                                </div>
                            </div>
                        </Panel>
                        <Divider style={{ margin: "0" }} />
                        <Panel header={customPanelHeader("БЕСКЛЮЧЕВОЙ ДОСТУП В ПОДЪЕЗД И КВАРТИРУ", "06")} key="6">
                            <div className="content">
                                <div className="img" style={{ backgroundImage: `url(${Adv6Img})` }} alt="" />
                                <div className="text">
                                    <p>
                                        Квартиры RAMS Signature оснащены умными дверными замками, которые сочетают возможности электронного и традиционного замка. Умный замок
                                        открывается и закрывается с помощью карты доступа, временного кода и металлического ключа
                                    </p>
                                </div>
                            </div>
                        </Panel>
                        <Divider style={{ margin: "0" }} />
                        <Panel header={customPanelHeader("ИННОВАЦИОННАЯ СИСТЕМА ALL-IN-ONE", "07")} key="7">
                            <div className="content">
                                <div className="img" style={{ backgroundImage: `url(${Adv7Img})` }} alt="" />
                                <div className="text">
                                    <p>
                                        В одних сутках 24 часа, но этого времени не всегда хватает для того, чтобы успевать делать всё желаемое. All-In-One — это функциональные и
                                        развлекательные помещения, расположенные в домах жилого комплекса. Здесь вы экономите драгоценные часы и проводите их с пользой для себя и
                                        близких. RAMS Signature позволяет планировать время в вашу пользу.
                                    </p>
                                </div>
                            </div>
                        </Panel>
                        <Divider style={{ margin: "0" }} />
                        {/* <Panel header={customPanelHeader('БЕЗОПАСНОСТЬ', "08")} key="8">
                            <div className="content">
                                <div className="img" style={{ backgroundImage: `url(${Adv8Img})` }} alt="" />
                                <div className="text">
                                    <p>Страховая компания Халык имеет оценку «B++» (Хороший) от Международного Агенства A.M. Best, что подтверждает финансовую надежность и высокую деловую репутацию.
                                        В СК выстроена надежная система защиты страхового портфеля и принимаемых обязательств по договорам страхования. Они разделяют ответственность перед клиентами с первоклассными международными перестраховщиками, такими как Hannover Re, Lloyd’s, HDI, AIG Europe и 11 другими партнерами.
                                        Клиентами региональной сети СК Халык являются крупные предприятия строительной, химической, металлургической, авиационной, космической и других отраслей.</p>
                                </div>
                            </div>
                        </Panel>
                        <Divider style={{ margin: '0' }} /> */}
                    </Collapse>
                </div>
            </div>
        </section>
    );
};

export default Advantages;
