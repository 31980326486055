const SVGComponent = (props) => (
    <svg
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.81744 9.71727H21V7.28268H4.81748L10.5662 1.72151L8.7866 0L0 8.5L8.7866 17L10.5662 15.2785L4.81744 9.71727Z"
        fill={props.color}
      />
    </svg>
  );
  export default SVGComponent;