const SVGComponent = (props) => (
    <svg
        viewBox="0 0 650 650"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle
            cx={324.999}
            cy={325.001}
            r={321.673}
            stroke="#3C5C75"
            strokeWidth={6}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
export default SVGComponent;