const SVGComponent = (props) => (
    <svg
      viewBox="0 0 1252 685"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-162.749 172.874C-132.866 238.593 8.21556 240.127 124.656 247.979C162.726 250.44 201.381 253.347 238.171 259.023C308.44 227.081 623.228 77.0589 1252 -86C321.195 -86 71.1074 -86 -64.9075 -86C-162.986 -1.98259 -195.739 109.61 -162.749 172.874ZM-200.666 181.244C-239.472 90.678 -175.263 -8.6741 -84.6792 -86H-1100V771.999H-408.07C-370.061 575.212 -13.9384 381.284 149.364 301.463C151.761 300.079 154.922 299.842 157.903 300.14C172.222 301.658 189.094 304.009 206.706 307.253C209.786 307.847 211.61 310.578 206.241 313.441C13.181 416.539 -328.287 626.354 -330.989 772H3.307C205.61 666.433 415.557 477.341 364.348 357.841C298.317 203.876 -137.033 329.656 -200.666 181.244Z"
        fill="url(#paint0_radial_643_181)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_643_181"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-230.5 76.0001) rotate(45.2417) scale(670.343 2274.98)"
        >
          <stop stopColor="#38877B" />
          <stop offset={1} stopColor="#09183B" />
        </radialGradient>
      </defs>
    </svg>
  );
  export default SVGComponent;