const SVGComponent = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1560 755"
      fill="none"
      {...props}
    >
      <path
        d="M242.298 87.7866C265.53 55.8581 295.611 25.4812 328.84 0H332.139C298.243 25.6813 267.523 56.5185 243.915 88.9633C200.763 148.268 181.633 212.544 209.564 260.497C217.536 274.183 231.956 283.885 251.15 290.838C270.339 297.789 294.185 301.95 320.84 304.63C347.489 307.309 376.888 308.504 407.17 309.546C411.117 309.682 415.08 309.815 419.053 309.949C445.463 310.837 472.348 311.741 498.454 313.529C848.449 152.699 1262.84 52.8992 1548.1 0H1559.13C1274.57 52.1966 856.082 151.973 502.613 313.822C545.482 316.928 586.15 322.566 619.032 334.66C652.922 347.124 678.677 366.5 689.949 397.155C707.528 444.958 682.773 501.954 634.251 559.519C585.67 617.155 513.001 675.686 433.986 726.715C418.938 736.434 403.657 745.881 388.267 755H384.335C400.687 745.364 416.925 735.353 432.901 725.035C511.827 674.064 584.316 615.657 632.721 558.231C681.185 500.734 705.244 444.542 688.072 397.845C677.078 367.948 651.929 348.89 618.341 336.537C584.806 324.203 542.993 318.606 498.826 315.56C482.554 323.04 466.422 330.653 450.449 338.4C292.758 414.875 152.749 536.649 69.0448 645.519C38.9844 684.618 16.2211 722.007 2.49665 755H0.332031C14.1212 721.58 37.1222 683.759 67.4593 644.3C151.344 535.194 291.593 413.217 449.577 336.6C464.469 329.378 479.5 322.271 494.653 315.28C469.737 313.654 444.164 312.794 418.986 311.948L418.965 311.947C414.999 311.814 411.044 311.68 407.102 311.545C376.822 310.503 347.362 309.307 320.64 306.62C293.924 303.934 269.888 299.753 250.469 292.718C231.054 285.685 216.139 275.758 207.836 261.503C179.267 212.456 199.043 147.232 242.298 87.7866Z"
        fill="url(#paint0_radial_692_92)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_692_92"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(374.909 477.838) rotate(-77.349) scale(477.617 1528.22)"
        >
          <stop stopColor="#38877B" />
          <stop offset={1} stopColor="#09183B" />
        </radialGradient>
      </defs>
    </svg>
  );
  export default SVGComponent;