import React, { useState, useEffect, useContext } from 'react';
import BannerSvg from "../../svg/BannerSvg";
import "./Baner.css";
import { Parallax } from 'react-scroll-parallax';
import BannerLogo from "../../svg/HeaderLogoSvg";
import Banner2Svg from "../../svg/Banner2Svg";
import VideoSvg from "../../svg/VideoSvg";
import VideoPlaySvg from "../../svg/VideoPlaySvg";
import Modal from "../modal/Modal";
import { AppContext } from '../context/context';


const Baner = () => {
  const { isOpen, setIsOpen } = useContext(AppContext);
  const [isVOpen, setIsVOpen] = useState(false);
  const texts = [
    { h11: 'Кинотеатр', h12: ' не выходя из дома', h2: 'Рассрочка 0% до 24 месяца' },
    { h11: 'Сильные школы', h12: 'и университеты рядом', h2: 'Первоначальный взнос 30%' },
    { h11: 'Квартиры', h12: ' в сердце города', h2: 'Рассрочка 0% до 24 месяца' },
    { h11: 'Квартиры', h12: 'в новом формате чистовой отделки', h2: 'Рассрочка от 0% до 24 месяца' }
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeClass, setFadeClass] = useState('fade-in-up');

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeClass('fade-in-up fade-out-down');
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setFadeClass('fade-in-up');
      }, 1000);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scaleFactor = 1 + (scrollPosition / 8000); // Adjust the scaling factor as needed

      // Apply the scaling effect
      const container = document.getElementById('banner');
      if (container) {
        container.style.backgroundSize = `${100 * scaleFactor}%`;
      }
    };

    const handleResize = () => {
      if (window.innerWidth > 910) {
        window.addEventListener('scroll', handleScroll);
      } else {
        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize(); // Call initially to set up the scroll event based on the window size

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section id='banner'>
      {/* <div className="vid"> */}
      <VideoSvg className="vid" onClick={() => setIsVOpen(true)} />
      <VideoPlaySvg className="play" onClick={() => setIsVOpen(true)}/>
      {/* </div> */}
      <Parallax translateX={[0, -20]}>
        <BannerSvg className="blue1" />
        <Banner2Svg className="blue2" />
      </Parallax>

      <div className="content">
        <BannerLogo className="logo" />
        <div className='text'>
          <div>
            <h1 className={fadeClass}>{texts[currentIndex].h11}</h1>
            <h1 className={fadeClass}>{texts[currentIndex].h12}</h1>
          </div>
          <hr />
          {/* <h2 className={fadeClass}>{texts[currentIndex].h2}</h2> */}
          <button onClick={() => setIsOpen(true)}>Получить консультацию</button>
        </div>
      </div>
      <Modal setIsOpen={setIsVOpen} isOpen={isVOpen}>
        <div className="iframe-con">
          <iframe id="youtubeiframe553849927" class="t331__iframe" width="100%" height="100%" src="https://www.youtube.com/embed/IX7Otd3WYfI?autoplay=1&amp;rel=0" frameborder="0" allowfullscreen=""></iframe>
        </div>
      </Modal>
    </section>
  )
}

export default Baner;